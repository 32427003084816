import React from 'react'
import Parse from 'parse/dist/parse.min.js';
import { useEffect } from 'react';
import { useState } from 'react';
import { Button, Card, Carousel, Image, Modal, Skeleton } from 'antd';
import "./homepage.scss";
import MyRow from '../../components/myRow/MyRow';
import Title from 'antd/es/typography/Title';
import Paragraph from 'antd/es/typography/Paragraph';
import MyCol from '../../components/myCol/MyCol';
import { useSelector } from 'react-redux';
import GlobalAction from '../../actions/GlobalAction';
import visionBk from '../../images/vision.jpg'
import ContainerFull from '../../components/container/ContainerFull';
import { useTranslation } from 'react-i18next';
const { Meta } = Card;


const Home = (props) => {


  const [slider, setSlider] = useState([])
  const [loadingSlider, setLoadingSlider] = useState(false)
  const [aboutPara, setAboutPara] = useState()
  const [visionPara, setVisionPara] = useState()
  const [careerList, setCareerList] = useState([])


  const [careerInfo, setCareerInfo] = useState()
  const [isModalOpen, setIsModalOpen] = useState(false);

  const lang = useSelector((stat) => {
    return stat.AuthReducer.lang
  })
  const { t } = useTranslation()

  const onClickInfo = (index) => {
    setCareerInfo(careerList[index])
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setCareerInfo(null)

  };

  async function fetchSlider() {
    const query = new Parse.Query('Slider');
    // Add a filter condition to the query
    query.equalTo('active', true);

    const Slider = await query.find();
    setSlider([...Slider])
  }

  async function fetchWebContint() {
    const query = new Parse.Query('WebContint');
    // Add a filter condition postion to the query
    query.containedIn('postion', [1, 2]);
    const WebContint = await query.find();


    let aboutData = WebContint.find(e => e.get("postion") === 1)
    let visionData = WebContint.find(e => e.get("postion") === 2)
    setAboutPara(aboutData)
    setVisionPara(visionData)
  }

  async function fetchCareer() {
    const query = new Parse.Query('Career');
    query.equalTo('active', true);

    const Career = await query.find();
    setCareerList([...Career])

  }


  useEffect(() => {
    setLoadingSlider(true)
    fetchSlider()
    fetchWebContint()
    fetchCareer()
    setLoadingSlider(false)

  }, [])


  const screenSize = useSelector((stat) => {
    return stat.AuthReducer.screenSize
  })


  return (
    <ContainerFull className='homepage' loading={loadingSlider} >

      <section className='sliderSection'>

        <Carousel
          autoplay={true}
          className='homepageSlider'
          swipe
          draggable={true}
        >
          {slider && slider.length > 0 && slider.map((slide, index) => {
            return <div className='homePageSlide' key={index}  >

              <Image
                preview={false}
                src={slide.get("Image")._url}
                className='sliderImage'
                rootClassName='sliderImageRoot'
                placeholder={
                  <Skeleton.Image active={true}
                    className='sliderImageRoot'
                  />
                }
              />
              <MyRow className={'sliderBtnRow'} >
                <MyCol>

                  <Button size='large' type='primary' className='sliderBtn'>
                    {t("More Info")}
                  </Button>
                </MyCol>
              </MyRow>
            </div>
          })}
        </Carousel>
      </section>
      <section id={"aboutus"} className='aboutSection'>
        <MyRow>
          <Title className='aboutUsTitle' level={1} >{t("ABOUT US")}</Title>
        </MyRow>

        <MyRow>
          <MyCol
            xs={22}
            sm={20}
            md={20}
          >
            <Paragraph className='aboutUsPara'  >
              {aboutPara &&
                lang !== "ar" ? aboutPara?.get("description") : aboutPara?.get("descriptionAr")
              }
            </Paragraph>
          </MyCol>
        </MyRow>

      </section>
      <section id={"vision"} className='visionSection'>
        <Image
          src={visionBk}
          preview={false}
          className={lang === "ar" ? "visionBkAr" : "visionBk"}
          rootClassName='visionBkRoot'
        />
        <MyRow>
          <Title className='visionTitle' level={1} >{t("vision & Mission")}</Title>
        </MyRow>

        <MyRow>
          <MyCol
            xs={22}
            sm={20}
            md={20}
          >
            <MyRow justify={"start"} >
              <MyCol xs={22} sm={18} md={12} lg={12}>
                <Paragraph className='visionPara'  >
                  {visionPara &&
                    lang !== "ar" ? visionPara?.get("description") : visionPara?.get("descriptionAr")
                  }
                </Paragraph>
              </MyCol>
            </MyRow>

          </MyCol>
        </MyRow>
      </section>

      <section id={"career"} className='careersSection'>
        <MyRow>
          <Title className='careersTitle' level={1} >{t("CAREERS")}</Title>
        </MyRow>

        <MyRow className={"mt-4"} >
          <MyCol xs={22}
            sm={20}
            md={20}
          >
            <MyRow justify={screenSize !== GlobalAction.screen.mobile ? "start" : "center"} gutter={[15, 30]} >
              {careerList && careerList.length > 0 && careerList.map((career, index) => {
                return <MyCol xs={22} sm={22} md={12} lg={8} key={index} >
                  <Card
                    bordered={false}
                    className='careerCard'
                    cover={
                      <Image
                        width={"100%"}
                        height={"auto"}
                        rootClassName='ImageCardRoot'
                        src={career?.get("cover")?._url}
                        placeholder={
                          <Skeleton.Image active={true}
                            className='ImageCardRoot placeHolderImage'
                          />
                        }
                      />

                    }

                  >
                    <div class="line"></div>
                    <Meta
                      title={<Title className='cardTitle' level={4} >{
                        lang === "ar" ? career?.get("JobTitleAr")?.toLowerCase() : career?.get("jobTile")?.toLowerCase()

                      }</Title>}
                      description={<Paragraph
                        ellipsis={{
                          rows: 4,
                          expandable: false,
                        }}
                        className='cardDescription'
                      >
                        {
                          lang === "ar" ? career?.get("descriptionAr")?.toLowerCase() : career?.get("description")?.toLowerCase()
                        }
                      </Paragraph>}
                    />
                    <Button
                      onClick={() => { onClickInfo(index) }}
                      className='mt-3 cardMoreBtn' size={"large"} type='primary'>{t("More Info")}</Button>
                  </Card>
                </MyCol>
              })}

            </MyRow>
          </MyCol>

        </MyRow>
        {careerInfo &&
          <Modal
            width={"80%"}
            title={
              lang === "ar" ? careerInfo?.get("JobTitleAr") : careerInfo?.get("jobTile")} open={isModalOpen} footer={false} onCancel={handleCancel}>
            <Image
              src={careerInfo?.get("cover")?._url}
              preview={false}
              width={"100%"}
              height={"auto"}

            />
            <Paragraph
            >
              {lang === "ar" ? careerInfo?.get("descriptionAr") : careerInfo?.get("description")
              }
            </Paragraph>
            {careerInfo?.get("email") &&
              <MyRow>
                You Can Send CV on Email :
                <a href={`mailto:${careerInfo?.get("email")}`}>
                  {careerInfo?.get("email")}</a>
              </MyRow>}

          </Modal>}
      </section>
    </ContainerFull>
  )
}

export default Home