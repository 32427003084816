import React, { useEffect, useState } from 'react'
import MyRow from '../../components/myRow/MyRow'
import MyCol from '../../components/myCol/MyCol'
import { Button, Drawer, Image } from 'antd'
import logo from '../../images/VivanceLogo.png'
import './MyHeader.scss'
import { useSelector } from 'react-redux'
import GlobalAction from '../../actions/GlobalAction'
import { AiOutlineMenu } from 'react-icons/ai';
import i18next from 'i18next'
import { useTranslation } from 'react-i18next'

const MyHeader = (props) => {
    const [open, setOpen] = useState(false);


    const goToView = (id) => {
        let element = document.getElementById(id)
        if (element) {
            onClose()
            element.scrollIntoView({
                behavior: "smooth"
            });
        }
    }

    const screenSize = useSelector((stat) => {
        return stat.AuthReducer.screenSize
    })

    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const lang = useSelector((stat) => {
        return stat.AuthReducer.lang
    })
    const { t } = useTranslation()

    return (
        <div className='MyHeader' >
            <div className='MyHeaderDiv' >
                <MyRow align={"middle"} justify={"space-between"} >
                    <MyCol>
                        <Image
                            src={logo}
                            preview={false}
                            width={screenSize === GlobalAction.screen.mobile ? 150 : 180}
                        />

                    </MyCol>
                    {screenSize !== GlobalAction.screen.mobile ?
                        <MyCol span={12} >
                            <MyRow gutter={15} justify={"end"} >
                                <MyCol
                                    onClick={() => goToView("aboutus")}
                                >
                                    <a className={"HeaderLink"}>
                                        {t("About Us")}
                                    </a>



                                </MyCol>
                                <MyCol
                                    onClick={() => goToView("vision")}
                                >
                                    <a className={"HeaderLink"}>
                                        {t("vision & Mission")}
                                    </a>




                                </MyCol>
                                <MyCol
                                    onClick={() => goToView("career")}
                                   >

                                    <a className={"HeaderLink"}>
                                        {t("Career")}

                                    </a>
                                </MyCol>

                                <MyCol
                                    onClick={() => goToView("footer")}
                                    >

                                    <a className={"HeaderLink"}>
                                        {t("Contact Us")}

                                    </a>
                                </MyCol>
                                {lang === "ar" ?
                                    <MyCol
                                        onClick={() => i18next.changeLanguage("en")}
                                        >
                                        <a className={"HeaderLink"}>
                                            En
                                        </a>
                                    </MyCol>
                                    :
                                    <MyCol
                                        onClick={() => i18next.changeLanguage("ar")}
                                        >
                                        <a className={"HeaderLink"}>
                                            AR
                                        </a>
                                    </MyCol>}
                            </MyRow>
                        </MyCol>
                        :
                        <>
                            <MyCol  >
                                <Button size='large' type="primary" onClick={showDrawer}>
                                    <AiOutlineMenu />
                                </Button>
                            </MyCol>

                            <Drawer theme={"dark"} title={false} placement="right" onClose={onClose} open={open}>
                                <p
                                    onClick={() => goToView("aboutus")}
                                    className='Drawer_HeaderLink'  >{t("About Us")}</p>

                                <p
                                    onClick={() => goToView("vision")}
                                    className='Drawer_HeaderLink'  >{t("Our Vision")}</p>

                                <p
                                    onClick={() => goToView("career")}
                                    className='Drawer_HeaderLink'  >{t("Career")}</p>

                                <p
                                    onClick={() => goToView("footer")}
                                    className='Drawer_HeaderLink'  >{t("Contact Us")}</p>
                                {lang === "ar" ?
                                    <p
                                        onClick={() => i18next.changeLanguage("en")}
                                        className='Drawer_HeaderLink'  >En</p>
                                    :
                                    <p
                                        onClick={() => i18next.changeLanguage("ar")}
                                        className='Drawer_HeaderLink'  >AR</p>
                                }
                            </Drawer>
                        </>
                    }
                </MyRow>
            </div>
        </div>
    )
}

export default MyHeader