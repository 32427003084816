import React from 'react';
import MyLoadingOutlined from '../myLoadingOutlined/MyLoadingOutlined';
import './Container.css';

const ContainerFull = (props) => {

    const { children, className, loading } = props;

    return (
        <>
        {/* <span className='background-app'></span> */}
          <div className={`wrapper-full ${className || ''}`}>
            {loading ? <MyLoadingOutlined /> : children}
        </div>
        
        

        </>
      
    );
};

export default ContainerFull;