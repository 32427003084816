// import history from "../appRoute/history";
import { history } from "../App";
import i18n from "../i18n";
// import ApiStorage from "../reducers/ApiStorage";

export const getLng = (path) => { 
    return UtilRoute.getRedirectLink(path);
}

const UtilRoute = {
    getRedirectLink: (path) => {
        return `/${i18n.language}${path.startsWith('/') ? '' : '/'}${path}`;
    },
    changeRouterLanguage: (lang) => {
        let path = window.location.pathname.split('/').splice(2);
        path.unshift(`/${lang}`);
        path = path.join('/');
        if (path !== window.location.pathname) {
            history.push({ pathname: path, search: window.location.search });
        }
    },
    redirectBack: () => {
        history.goBack();
    },
    redirectPathNewTabWindowFile: (fileName) => {
        window.open(process.env.REACT_APP_BASE_URL + '/file?fileName=' + fileName, "_blank");
    },
    redirectPathNewTab: (path, search, state) => {
        window.open(UtilRoute.getRedirectLink(path), "_blank");
        // history.push({ pathname: path, })
    },
    redirectPath: (navigate, path, search, state) => {
        let loc = { pathname: UtilRoute.getRedirectLink(path), search: search, state: state };
        console.log('loc = ', loc);
        // navigate(loc, { replace: true });
        navigate(loc);
        // history.push({ pathname: path, }, { replace: true })
        // window.location.href = getRedirectLink(path);
    },
}

export default UtilRoute;