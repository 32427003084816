import {
    SET_LANG,
    SET_SCREEN_SIZE,

} from "./AuthReducer";
import { store } from "./store";

const ApiStorage = {
    setLanguage: async (lang) => {
        await store.dispatch({ type: SET_LANG, payload: lang });
    },

    setScreenSize: async (screenSize) => {
        await store.dispatch({ type: SET_SCREEN_SIZE, payload: screenSize });
    },

    getLanguage: () => {
        const { lang } = store.getState().AuthReducer;
        if (lang) return lang;
        return 'en';
    },
    getDir: () => {
        const { lang } = store.getState().AuthReducer;
        if (lang) return lang === 'ar' ? 'rtl' : 'lrt';
        return 'ltr';
    },

    getScreenSize: () => {
        const { screenSize } = store.getState().AuthReducer;
        return screenSize;
    },
}


export default ApiStorage;