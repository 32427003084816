import { React } from 'react';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/lib/integration/react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from "react-router-dom";
import { store, persistor } from './reducers/store';
import AppRoute from './appRoute/AppRoute';
import 'bootstrap/dist/css/bootstrap.min.css';

import './App.css';

import { createBrowserHistory } from "history";
import { ConfigProvider } from 'antd';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
const history = createBrowserHistory();

export { history };

function App() {
  const { i18n } = useTranslation();

  return (
    <Provider store={store}>
      <PersistGate
        persistor={persistor}>
        <BrowserRouter
          history={history}
          forceRefresh={true}
        >
          <ConfigProvider 
          direction={i18n.dir()}
          theme={
            {
              "token": {
                "colorPrimary": "#f9b800",
                "borderRadius": 1,
                fontFamily: i18n.language === "en" ? "'Montserrat', sans-serif" :"'Tajawal', sans-serif"
                
              },
              
              "components": {
                "Tabs": {
                  "colorBorder": "#f9b800",
                  "colorBorderSecondary": "none"
                }
              }
            }
          }>

            <AppRoute />
          </ConfigProvider>
          {/* <ToastContainer /> */}
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
