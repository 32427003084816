import { useEffect } from 'react';
import { cloneElement } from 'react';
import MyFooter from './MyFooter';
import MyHeader from './MyHeader';
import { Layout } from 'antd';
import { Content, Footer, Header } from 'antd/es/layout/layout';
import './BlankLayout.css'
const BlankLayout = (props) => {
    const { children } = props;


    return (
        <Layout className="site-layout">
         
            <MyHeader/>
               
            <Content className={props.className ? props.className : ''}>
                {cloneElement(children, { ...props })}
            </Content>
            <MyFooter
            />
        </Layout>
       
    );
};

export default BlankLayout;