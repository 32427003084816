import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import "./MyLoadingOutlined.css";

const MyLoadingOutlined = () => {
    return (
        <div className="spin-wrapper">
            <LoadingOutlined />
        </div>
    );
};

export default MyLoadingOutlined;