import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import React, { Suspense } from 'react';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import 'react-toastify/dist/ReactToastify.css';
import MyLoadingOutlined from './components/myLoadingOutlined/MyLoadingOutlined';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import Parse from 'parse/dist/parse.min.js';

const PARSE_APPLICATION_ID = 'wXAz6Mkgzy8nuTxLlR0QJjjzhNMfQhN9W3t8XtQm';
const PARSE_HOST_URL = 'https://parseapi.back4app.com/';
const PARSE_JAVASCRIPT_KEY = 'J2HhlrongCdPBfpGSzAsNS6L9FGQ3214JgszRAai';
Parse.initialize(PARSE_APPLICATION_ID, PARSE_JAVASCRIPT_KEY);
Parse.serverURL = PARSE_HOST_URL;

const antIcon = <LoadingOutlined style={{ fontSize: 26, color: '#60196b' }} spin />;

Spin.setDefaultIndicator(<Spin indicator={antIcon} />);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
   <Suspense
    fallback={<MyLoadingOutlined />}
  >
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Suspense>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
