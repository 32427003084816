export const SET_LANG = 'SET_LANG';
export const SET_SCREEN_SIZE = 'SET_SCREEN_SIZE';


const intState = {
    lang: "en",
    screenSize: 'desktop',


}

export default function AuthReducer(state = intState, action) {
    switch (action.type) {
        case SET_LANG:
            return { ...state, lang: action.payload };
        case SET_SCREEN_SIZE:
            return { ...state, screenSize: action.payload };
        default:
            return state;
    }
}