
const fs = {

};

const loc = {
screen:{
    desktop:1,
    tablet:2,
    mobile:3
}
}

const GlobalAction = Object.assign(fs, loc);

export default GlobalAction;