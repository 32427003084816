import React, { useEffect, useState } from "react";
import {
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import BlankLayout from "../layouts/blankLayout/BlankLayout";
import ApiStorage from "../reducers/ApiStorage";
import Home from "../pages/home/Home";
import GlobalAction from "../actions/GlobalAction";


const AppRoute = (props) => {



    useEffect(() => {
        window.addEventListener("resize", () => {
            let cw = document.body.clientWidth;
            let screen = GlobalAction.screen.desktop;
            if (cw <= 770) {
                screen = GlobalAction.screen.mobile;
            } else if (cw <= 991) {
                screen = GlobalAction.screen.tablet;
            }
            ApiStorage.setScreenSize(screen);
        });
        let cw = document.body.clientWidth;
        let screen = GlobalAction.screen.desktop;
        if (cw <= 770) {
            screen = GlobalAction.screen.mobile;
        } else if (cw <= 991) {
            screen = GlobalAction.screen.tablet;
        }
        ApiStorage.setScreenSize(screen);
        /* eslint-disable */
    }, [document.body.clientWidth])


    return (
        <Routes>
          
                <>
                    <Route
                        exact
                        path="/:lng/home"
                        element={
                            <BlankLayout>
                                <Home
                                />
                            </BlankLayout>
                        }
                    />


                    <Route
                        path="*"
                        replace
                        element={
                            <Navigate
                                to={`/en/home`}
                                replace={true}
                            />
                        }
                    />
                </>
            
        </Routes>
    );
};

export default AppRoute;