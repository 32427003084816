import React, { useEffect, useState } from 'react'
import './MyFooter.scss'
import MyRow from '../../components/myRow/MyRow'
import MyCol from '../../components/myCol/MyCol'
import logo from '../../images/VivanceLogo.png'
import { Image, Tooltip } from 'antd'
import Title from 'antd/es/typography/Title'
import Link from 'antd/es/typography/Link'
import { AiOutlineHome, AiTwotoneMail } from 'react-icons/ai';
import { BsFillTelephoneFill } from 'react-icons/bs';
import Parse from 'parse/dist/parse.min.js';
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'


const MyFooter = (props) => {

    const [loading, setLoading] = useState(false)
    const [footerList2, setFooterList2] = useState([])
    const [footerList1, setFooterList1] = useState([])
    const [companyLocation, setCompanyLocation] = useState()
    const [companyEmail, setCompanyEmail] = useState()
    const [companyMobile, setCompanyMobile] = useState()
    const [footerList1Title, setFooterList1Title] = useState()
    const [footerList2Title, setFooterList2Title] = useState()
    const [social, setSocial] = useState()


    async function fetchWebContint() {
        const query = new Parse.Query('WebContint');
        query.containedIn('postion', [3, 4, 5, 6, 7]);

        const WebContint = await query.find();
        let footerList2Data = WebContint.find(e => e.get("postion") === 3)
        let footerList1Data = WebContint.find(e => e.get("postion") === 4)
        let companyLocationData = WebContint.find(e => e.get("postion") === 5)
        let companyEmailData = WebContint.find(e => e.get("postion") === 6)
        let companyMobileData = WebContint.find(e => e.get("postion") === 7)
        setFooterList2(footerList2Data.get("list"))
        setFooterList1(footerList1Data.get("list"))
        setFooterList1Title(footerList1Data)
        setFooterList2Title(footerList2Data)
        setCompanyLocation(companyLocationData)
        setCompanyEmail(companyEmailData)
        setCompanyMobile(companyMobileData)

    }

    async function fetchSocial() {
        const query = new Parse.Query('Social');
        const Social = await query.find();
        setSocial(Social.map((item, index) => item.toJSON()))
    }


    useEffect(() => {
        setLoading(true)
        fetchWebContint()
        fetchSocial()
        setLoading(false)

    }, [])
    const lang = useSelector((stat) => {
        return stat.AuthReducer.lang
    })
    const { t } = useTranslation()

    return (
        <div id={"footer"} className='MyFooter' >
            <MyRow>
                <MyCol
                    xs={22}
                    sm={20}
                    md={20}
                >
                    <MyRow gutter={[5, 15]}>
                        <MyCol xs={22} md={9} sm={12} >
                            <Image
                                preview={false}
                                src={logo}
                                width={180}

                            />
                            <div className='footerCircleDiv' >
                                {social && social.length > 0 && social.map((item, index) => {
                                    return <a className='footerCircle' target='_blank' href={item?.link}>
                                        <Image
                                            preview={false}
                                            src={item?.icon?.url}
                                            rootClassName='SocialRootIcon'
                                            className='socialIcon'

                                        />
                                    </a>

                                })}

                            </div>
                        </MyCol>

                        <MyCol xs={22} md={5} sm={12} >
                            <Title level={2} className='MenuTile' >


                                {lang === "ar" ? footerList1Title?.get("linkTitleAr") : footerList1Title?.get("linkTitle")}

                            </Title>
                            <div className='footerMenu'>
                                {footerList1 && footerList1?.length > 0 && footerList1.map((item, index) => {
                                    return <div key={index} >
                                        <a href={item?.link} >
                                            {lang === "ar" ? item?.linkTitleAr : item?.linkTitle}
                                        </a>
                                    </div>
                                })}

                            </div>

                        </MyCol>
                        <MyCol xs={22} md={5} sm={12} >
                            <Title level={2} className='MenuTile' >
                                {lang === "ar" ? footerList2Title?.get("linkTitleAr") : footerList2Title?.get("linkTitle")}

                            </Title>
                            <div className='footerMenu'>
                                {footerList2 && footerList2?.length > 0 && footerList2.map((item, index) => {
                                    return <div key={index} >
                                        <a href={item?.link} >
                                            {lang === "ar" ? item?.linkTitleAr : item?.linkTitle}
                                        </a>
                                    </div>
                                })}

                            </div>

                        </MyCol>
                        <MyCol xs={22} md={5} sm={12} >

                            <Title level={2} className='MenuTile' >
                                {t("Our Company")}
                            </Title>
                            <div className='footerMenu'>
                                <div className='companyItem'  >
                                    <AiOutlineHome className='icon' />
                                    <span className='LisItem' >
                                        <Tooltip title={t("Open Map")}>
                                            <a href={`${companyLocation?.get("link")}`} target='_blank' >
                                            {/* https://www.google.com/maps/place/33.51982260614267, 36.29090468713937 */}
                                                {lang === "ar" ? companyLocation?.get("linkTitleAr") : companyLocation?.get("linkTitle")}
                                            </a></Tooltip>

                                    </span>
                                </div>
                                <div className='companyItem'  >
                                    <AiTwotoneMail className='icon' />
                                    <span className='LisItem' >
                                        <a href={`mailto:${companyEmail?.get("link")}`} target='_blank' >
                                            {lang === "ar" ? companyEmail?.get("linkTitleAr") : companyEmail?.get("linkTitle")}


                                        </a>
                                    </span>
                                </div>
                                <div className='companyItem'  >
                                    <BsFillTelephoneFill className='icon' />
                                    <span className='LisItem' >
                                        <a href={`tel:${companyMobile?.get("link")}`} target='_blank' >
                                            {lang === "ar" ? companyMobile?.get("linkTitleAr") : companyMobile?.get("linkTitle")}

                                        </a>
                                    </span>
                                </div>
                                <div className='companyItem'  >
                                    <span className='LisItem' >
                                        <Link>
                                            Vivance
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </MyCol>
                    </MyRow>
                </MyCol>
            </MyRow>
        </div>
    )
}

export default MyFooter